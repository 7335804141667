// Core variables and mixins
@import "res/scss/__variables.scss";
@import "res/scss/__mixins.scss";

.main-use {
  background-color:#fff;  
  .consulting-slider-nav {
    max-width:1290px;
    margin-left:auto;
    margin-right:auto;
  }
  .consulting-items {
    margin:0 10px;
    line-height:1.33;
    font-size:15px;
    text-align:center;
    letter-spacing: -0.04em;
    color:#777;
    cursor: pointer;
    @include media-query-min(sm){
      margin:0 22px;
    }
    &:before {
      display: flex;
      content:'';
      align-items:center;
      width:80px;
      height:80px;
      margin: 0 auto 10px;
      border-radius: 50%;
      border:1px solid #ddd;
      background-repeat:no-repeat;
      background-position:center;
      background-size:40px;
      @include media-query-min(414px){
        width:85px;
        height:85px;
      }
    }
    &.slick-current {
      font-weight:600;
      color:#097587;
      &:before {
        border:1px solid $g_point-theme-color;
        background-color:$g_point-theme-color;
      }
    }
    @include media-query-min(lg){
      &:hover{
        font-weight:600;
        color:#097587;
        &:before {
          border:1px solid $g_point-theme-color;
          background-color:$g_point-theme-color;
        }
      }
    }
  }
  .consulting-items.items-01{
    &:before {
      background-image:url(/views/res/imgs/main/consulting_slide/icon_consulting_01.svg);
    }
    &.slick-current {
     &:before {
        background-image:url(/views/res/imgs/main/consulting_slide/icon_consulting_01_on.svg);
      } 
    }
    @include media-query-min(lg){
      &:hover:before{
        background-image:url(/views/res/imgs/main/consulting_slide/icon_consulting_01_on.svg);
      }
    }
  }
  .consulting-items.items-02{
    &:before {
      background-image:url(/views/res/imgs/main/consulting_slide/icon_consulting_02.svg);
    }
    &.slick-current {
     &:before {
        background-image:url(/views/res/imgs/main/consulting_slide/icon_consulting_02_on.svg);
      } 
    }
    @include media-query-min(lg){
      &:hover:before{
        background-image:url(/views/res/imgs/main/consulting_slide/icon_consulting_02_on.svg);
      }
    }
  }
  .consulting-items.items-03{
    &:before {
      background-image:url(/views/res/imgs/main/consulting_slide/icon_consulting_03.svg);
    }
    &.slick-current {
     &:before {
        background-image:url(/views/res/imgs/main/consulting_slide/icon_consulting_03_on.svg);
      } 
    }
    @include media-query-min(lg){
      &:hover:before{
        background-image:url(/views/res/imgs/main/consulting_slide/icon_consulting_03_on.svg);
      }
    }
  }
  .consulting-items.items-04{
    &:before {
      background-image:url(/views/res/imgs/main/consulting_slide/icon_consulting_04.svg);
    }
    &.slick-current {
     &:before {
        background-image:url(/views/res/imgs/main/consulting_slide/icon_consulting_04_on.svg);
      } 
    }
    @include media-query-min(lg){
      &:hover:before{
        background-image:url(/views/res/imgs/main/consulting_slide/icon_consulting_04_on.svg);
      }
    }
  }
  .consulting-items.items-05{
    &:before {
      background-image:url(/views/res/imgs/main/consulting_slide/icon_consulting_05.svg);
    }
    &.slick-current {
     &:before {
        background-image:url(/views/res/imgs/main/consulting_slide/icon_consulting_05_on.svg);
      } 
    }
    @include media-query-min(lg){
      &:hover:before{
        background-image:url(/views/res/imgs/main/consulting_slide/icon_consulting_05_on.svg);
      }
    }
  }
  .consulting-items.items-06{
    &:before {
      background-image:url(/views/res/imgs/main/consulting_slide/icon_consulting_06.svg);
    }
    &.slick-current {
     &:before {
        background-image:url(/views/res/imgs/main/consulting_slide/icon_consulting_06_on.svg);
      } 
    }
    @include media-query-min(lg){
      &:hover:before{
        background-image:url(/views/res/imgs/main/consulting_slide/icon_consulting_06_on.svg);
      }
    }
  }
  .consulting-items.items-07{
    &:before {
      background-image:url(/views/res/imgs/main/consulting_slide/icon_consulting_07.svg);
    }
    &.slick-current {
     &:before {
        background-image:url(/views/res/imgs/main/consulting_slide/icon_consulting_07_on.svg);
      } 
    }
    @include media-query-min(lg){
      &:hover:before{
        background-image:url(/views/res/imgs/main/consulting_slide/icon_consulting_07_on.svg);
      }
    }
  }
  .consulting-items.items-08{
    &:before {
      background-image:url(/views/res/imgs/main/consulting_slide/icon_consulting_08.svg);
    }
    &.slick-current {
     &:before {
        background-image:url(/views/res/imgs/main/consulting_slide/icon_consulting_08_on.svg);
      } 
    }
    @include media-query-min(lg){
      &:hover:before{
        background-image:url(/views/res/imgs/main/consulting_slide/icon_consulting_08_on.svg);
      }
    }
  }
  .consulting-items.items-09{
    &:before {
      background-image:url(/views/res/imgs/main/consulting_slide/icon_consulting_09.svg);
    }
    &.slick-current {
     &:before {
        background-image:url(/views/res/imgs/main/consulting_slide/icon_consulting_09_on.svg);
      } 
    }
    @include media-query-min(lg){
      &:hover:before{
        background-image:url(/views/res/imgs/main/consulting_slide/icon_consulting_09_on.svg);
      }
    }
  }
  .consulting-items.items-10{
    &:before {
      background-image:url(/views/res/imgs/main/consulting_slide/icon_consulting_10.svg);
    }
    &.slick-current {
     &:before {
        background-image:url(/views/res/imgs/main/consulting_slide/icon_consulting_10_on.svg);
      } 
    }
    @include media-query-min(lg){
      &:hover:before{
        background-image:url(/views/res/imgs/main/consulting_slide/icon_consulting_10_on.svg);
      }
    }
  }
  // 하단 컨텐츠
  .consulting-slider-for {
    max-width:1376px;
    margin-left:auto;
    margin-right:auto;
    margin-top:25px;
    @include media-query-min(lg){
      margin-top:48px;
    }
    .slick-list {
      overflow:visible;
    }
    .slick-arrow {
      &.slick-prev {
        @include media-query-min(lg){
          left:4px;
        }
        @include media-query-min(1436px){
          left:-25px; 
        }
      }
      &.slick-next {
        @include media-query-min(lg){
          right:4px;
        }
        @media screen and (min-width: 1436px) {
          right:-25px; 
        }
      }
    }
    .consulting-box {
      opacity:.45;
      transition:opacity .3s;
      &.slick-current {
        opacity:1;
      }
      margin:0 15px;
      @include media-query-min(lg){
        margin:0 60px;
      }
      @include media-query-min(xl){
        margin:0 70px;
      }
      background-color:#333;
      color:#fff;
      .title {
        line-height:2;
        margin-top: 5px;
        margin-bottom: 13px;
        font-size:25px;
        @include media-query-min(lg){
          margin-top: 1px;
          font-size:30px;
        }
      }
      .desc {
        line-height:1.66;
        font-size:15px;
        @include media-query-min(lg){
          line-height:1.75;
          font-size:16px;
        }
      }

      .box-layout {
        display:flex;
        flex-direction: column;
        @include media-query-min(lg){
          flex-direction: row;
          min-height:544px;
          [class*='ie_'] & {
            height:544px;
          }
        }
        @include media-query-min(xl){
          min-height:auto;
        }
      }
      .box-text {
        order:2;
        padding:25px 20px 30px;
        @include media-query-min(lg){
          order:1;
          padding: 58px 40px 58px 48px;
          flex-basis: 36.5%;
        }
      }
      .box-imgs {
        order:1;
        background-repeat:no-repeat;
        background-position:center;
        background-size: cover;
        @include media-query-min(lg){
          order:2;
          flex:1 1 0px;
        }
        &:after {
          display:inline-block;
          content:'';
          padding-top: 48.5%;
          @include media-query-min(lg){
            padding-top: 64.47%;
          }
        }
      }
      .box-imgs.box-imgs-01 {
        background-image:url(/views/res/imgs/main/consulting_slide/img_consulting_01.jpg);
      }
      .box-imgs.box-imgs-02 {
        background-image:url(/views/res/imgs/main/consulting_slide/img_consulting_02.jpg);
      }
      .box-imgs.box-imgs-03 {
        background-image:url(/views/res/imgs/main/consulting_slide/img_consulting_03.jpg);
      }
      .box-imgs.box-imgs-04 {
        background-image:url(/views/res/imgs/main/consulting_slide/img_consulting_04.jpg);
      }
      .box-imgs.box-imgs-05 {
        background-image:url(/views/res/imgs/main/consulting_slide/img_consulting_05.jpg);
      }
      .box-imgs.box-imgs-06 {
        background-image:url(/views/res/imgs/main/consulting_slide/img_consulting_06.jpg);
      }
      .box-imgs.box-imgs-07 {
        background-image:url(/views/res/imgs/main/consulting_slide/img_consulting_07.jpg);
      }
      .box-imgs.box-imgs-08 {
        background-image:url(/views/res/imgs/main/consulting_slide/img_consulting_08.jpg);
      }
      .box-imgs.box-imgs-09 {
        background-image:url(/views/res/imgs/main/consulting_slide/img_consulting_09.jpg);
      }
      .box-imgs.box-imgs-10 {
        background-image:url(/views/res/imgs/main/consulting_slide/img_consulting_10.jpg);
      }
    }
  }
}